<template>
    <div :class="{'display-none': card != 'PDR', 'card': true}">
      <div :class="{'section-for-empty-items-list': isEmptyItems}" @contextmenu.prevent="addNewLine($event)">
        <div class="card-header bg-navyblue d-flex align-items-center">
          <div style="padding-left: 0px" class="col-sm-1">
            <strong>PDRs</strong>
          </div>
          <div class="col-sm-11 d-flex" style="justify-content: end">
            <div class="form-group row d-flex m-0 align-items-center">
              <label style="padding-left: 15px; min-width: 74px; text-align: right;" class="col-form-label">PDRs SubTotal</label>
              <div class="">
                <span class="form-control" style="width: 110px; min-width: 110px; display: inline; line-height: 32px; margin: 0 10px;">{{calculatedTotal|formatMoney}}</span>
              </div>
            </div>
            <div class="form-group row d-flex m-0 align-items-center">
              <label style="margin: 0 10px;" class="col-form-label ml-2">ADJ Subtotal</label>
              <div>
                        <span :class="{'white-text': calculatedTotal === calculatedAdjTotal || !ifOneApprovedSet}" class="form-control"
                              :style="{color: calculatedAdjTotal > calculatedTotal ? '#2BAE66FF' : 'red'}"
                              style=" width: 110px; min-width: 110px; display: inline; line-height: 16px;">{{calculatedAdjTotal|formatMoney}}</span>
                <span :class="{'white-text': calculatedTotal === calculatedAdjTotal || !ifOneApprovedSet}" class="form-control ml-2"
                      :style="{color: calculatedAdjTotal > calculatedTotal ? '#2BAE66FF' : 'red'}"
                              style="width: 110px; min-width: 110px; display: inline; line-height: 16px;">{{computedSymbolForPercents}}{{calculatedAdjTotalPercent}}%</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-block rs-scroll rs-scroll--x px-0 py-0 bg-white">
          <table class="table tab-special mb-0 estimate-items" id="table-1">
            <thead>
            <tr>
              <th class="v-text-top " style="width:100%; padding-left: 15px; min-width: 14rem;">Name</th>
              <th class="v-text-top "
                  style="width:100%; padding-left: 15px; min-width: 14rem;"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Add Comments for each line below', placement:'left'}">
                Comment
              </th>
              <th class="v-text-top text-center" style="min-width:7.3rem; ">Material</th>
              <th class="v-text-top text-center" style="min-width:7.3rem; ">Action</th>
              <th class="v-text-top text-center" style="min-width:7.3rem; ">Qty</th>
              <th class="v-text-top text-center numeric" style="min-width:6.5rem;">Rate</th>
              <th class="v-text-top text-center numeric"
                  style="min-width:6.5rem;"
                  v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Adjusted totals are shown for each line below', placement:'left'}">
                ADJ
              </th>
              <th class="v-text-top text-center" style="min-width:33px;">
                <span>Approved</span>
                <label class="form-check-inline checkbox"
                       style="margin-right:0; margin-top: 5px;"
                       v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Click to Approve all line items', placement:'left'}">
                  <input @click="changeAllApproved($event)" class="form-check-input" type="checkbox" :checked="ifAllApprovedSet" :disabled="isUserRoleLawyer">
                  <span class="icon"><i class='bx' :class="{'bx-check': ifAllApprovedSet}"></i></span>
                </label>
              </th>
            </tr>
            </thead>
            <tr v-for="(part, index) in items"
                @contextmenu.prevent.stop="handleRightClick($event, (() => {part.index = index; return part})())"
                :class="{'active': part.id === selectedId}"
                :key="'pdr'+index">
              <td class="align-top">
                <input type="text"
                       ref="name"
                       class="form-control"
                       v-model="part.name"
                       @change="changeItemPart(part)"
                       :readonly="!part.isManual"
                >
              </td>
              <td class="align-top">
                <div :class="{ 'for-report': part.approveStatus == 'reported', 'for-denied': part.approveStatus == 'denied' }">
                  <input
                      :ref="'comment'+index"
                      v-on:keydown.left="onKeyPressLeft('approved',$event,index,'edit')"
                      v-on:keydown.right="onKeyPressRight('adjPrice',$event,index,'edit')"
                      v-on:keydown.up="onKeyPressUp('comment',$event,index)"
                      v-on:keydown.down="onKeyPressDown('comment',$event,index)"
                      type="text"
                      class="form-control comment"
                      v-model="part.comment"
                      @change="changeItemPart(part)"
                      :readonly="isUserRoleLawyer"
                  >
                </div>
              </td>
              <td class="align-top">
                <input type="text"
                       ref="material"
                       class="form-control"
                       v-model="part.material"
                       readonly
                >
              </td>
              <td class="align-top">
                <input type="text"
                       ref="action"
                       class="form-control"
                       v-model="part.action"
                       readonly
                >
              </td>
              <td class="align-top">
                <input type="text"
                       ref="qty"
                       class="form-control"
                       v-model="part.qty"
                       readonly
                >
              </td>
              <td class="align-top">
                <number-formatter ref="total"
                                  type="text"
                                  v-model="part.price"
                                  class="form-control numeric"
                                  format="$0,0.00" :isDisabled="true"></number-formatter>
              </td>
              <td class="align-top">
                <!--                        <input v-if="part.adj_price == null || part.adj_price == ''" type="text" v-model="part.adj_price" class="form-control numeric adj">-->
                <number-formatter
                    :ref="'adjPrice'+index"
                    @onKeyLeft="onKeyPressRightLeft('comment',index)"
                    @onKeyRight="onKeyPressRightLeft('approved',index)"
                    @onKeyUp="onKeyPressUp('adjPrice',$event,index)"
                    @onKeyDown="onKeyPressDown('adjPrice',$event,index)"
                    :isTotalManual="true"
                    type="text"
                    v-model="part.adj_price"
                    class="form-control numeric adj"
                    format="0,0.00"
                    style="color:red"
                    @onChange="changeItemPart(part)"
                    :readonly="part.approveStatus == 'denied' || isUserRoleLawyer"
                ></number-formatter>
              </td>
              <td class="align-top d-flex">
                <label class="form-inline-check checkbox"
                       v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Click to Approve line item', placement:'left'}">
                  <input
                      :ref="'approved'+index"
                      v-on:keydown.left="onKeyPressLeft('adjPrice',$event,index)"
                      v-on:keydown.right="onKeyPressRight('comment',$event,index)"
                      v-on:keydown.up="onKeyPressUp('approved',$event,index)"
                      v-on:keydown.down="onKeyPressDown('approved',$event,index)"
                      type="checkbox" class="form-control form-check-input"
                      @change="handleInputChange($event, part)"
                      :disabled="loading || isUserRoleLawyer"
                      :checked="part.approveStatus !== 'none'">
                  <span class="icon" :class="{
                                'icon--success': part.approveStatus === 'approved',
                                'icon--danger': part.approveStatus === 'denied',
                                'icon--warning': part.approveStatus === 'reported'
                                }">
                                <i class='bx' :class="{
                                    'bx-check': part.approveStatus === 'approved',
                                    'bx-x': part.approveStatus === 'denied',
                                    'bx-question-mark': part.approveStatus === 'reported'
                                }"></i>
                            </span>
                </label>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
</template>
<script>
    /*eslint-disable */
    import NumberFormatter from '@/components/number-formatter';
    import adaptiveInput from '@/components/ps-adaptive-input';
    import ItemsArrowKeys from './mixins-items-arrow-keys';
    import accounting from 'accounting';
    import _ from "lodash";

    export default {
        name: 'items-type-pdr',
        mixins: [ItemsArrowKeys],
        props: [
            'card',
            'items',
            'selected', // index of selected item from 'items-list'
            'loading',
            'changeItemPart',
        ],
        data: function () {
            return {};
        },
        methods: {
            //...mapActions({
            //    changeItemPart: 'autoSave/changeItemPart',
            //}),
            addNewLine(e) {
              if (this.isEmptyItems) {
                this.$emit('addNewLine', e);
              }
            },
            changeAllApproved(e) {
                if (this.isNoItems) {
                    e.preventDefault();
                    return;
                }
                let status = event.target.checked;
                if (status === true) {
                    this.items.forEach(item => {
                        if (item.approveStatus === "none") {
                            item.approveStatus = 'approved';
                            this.changeItemPart(item);
                        }
                    });
                } else {
                    this.items.forEach(item => {
                        if (item.approveStatus === "approved") {
                            item.approveStatus = 'none';
                            this.changeItemPart(item);
                        }
                    });
                }
            },
            handleRightClick(e, part) {
                this.$emit('right-click', e, part);
            },
            handleInputChange(e, part) {
                if (!e.target.checked && part.approveStatus === 'denied') {
                    part.comment = '';
                    part.adj_price = null;
                }
                if (e.target.checked && part.approveStatus !== 'approved') {
                    this.items.forEach(item => {
                        if (item.id === part.id) {
                            e.target.checked = true;
                            item.approveStatus = 'approved';
                        }
                    });
                } else {
                    this.items.forEach(item => {
                        if (item.id === part.id) {
                            e.target.checked = false;
                            item.approveStatus = 'none';
                        }
                    });
                }
                // save to db
                // clear reportText if needed
                // item.reportText = '';
                this.save(part);
            },
            save(part) {
                this.$emit('change', part);
            }
        },
        computed: {
            isEmptyItems() {
              return _.isEmpty(this.items);
            },
            calculatedTotal() {
                let result = 0;
                for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i]) {
                        result += parseFloat(parseFloat(this.items[i].price||0).toFixed(2));
                    }
                }
                return parseFloat(result.toFixed(2));
            },
            calculatedAdjTotal() {
                let result = 0;
                for (let i = 0; i < this.items.length; i++) {
                    if (this.items[i]) {
                        if (this.items[i].approveStatus && (this.items[i].approveStatus === 'approved' || this.items[i].approveStatus === 'reported')) {
                            if (this.items[i].adj_price !== '' && this.items[i].adj_price != null) {
                                result += parseFloat(parseFloat(this.items[i].adj_price).toFixed(2));
                            } else {
                                result += parseFloat(parseFloat(this.items[i].price||0).toFixed(2));
                            }
                        }
                    }
                }
                return parseFloat(result.toFixed(2));
            },
            computedSymbolForPercents() {
              if (this.calculatedAdjTotal > this.calculatedTotal) {
                return '+';
              }
              return '-';
            },
            calculatedAdjTotalPercent() {
              if (this.calculatedTotal == 0 || !!(this.calculatedTotal === this.calculatedAdjTotal)) {
                return 0;
              }

              return accounting.toFixed(Math.abs( this.calculatedTotal-this.calculatedAdjTotal) / this.calculatedTotal * 100, 2);
            },
            isNoItems() {
                return (this.items.length === 0);
            },
            selectedId() {
                return this.selected ? this.selected.id : null;
            },
            ifAllApprovedSet() {
                if (this.isNoItems) {
                    return false;
                }
                let st = true;
                this.items.forEach(item => {
                    if (item.approveStatus === "none") {
                        st = false;
                    }
                });

                return st;
            },
            ifOneApprovedSet() {
                if (this.isNoItems) {
                    return false;
                }
                let st = false;
                st = this.items.some(item => {
                    return !!(item.approveStatus !== "none");
                });

                return st;
            },
        },
        watch: {},
        components: {
            NumberFormatter,
            adaptiveInput
        }
    };
</script>


<style scoped>

    .for-report .form-control.comment,
    .for-denied .form-control.comment {
        padding-left: 76px;
    }

    .for-report,
    .for-denied {
        position: relative;
        min-width: 200px;
    }

    .for-report::before,
    .for-denied::before {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        content: 'On Report:';
        color: red;
        z-index: 1;
        line-height: 32px;
        padding-left: .75rem;
        font-size: 12px;
        font-weight: 600;
    }

    .for-denied::before {
        content: 'Declined:';
    }

</style>
