<template>
    <div class="claimlinq-page">
        <div class="page-header">
            <h4>Assessments</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item">
                    <router-link :to="'/dashboard'">Home</router-link>
                </li>
                <li class="breadcrumb-item active">View Assessments</li>
            </ol>
            <div class="page-header-nav-btn">
                <a v-if="isAssessmentsCompany" @click="clickNewAssessment" class="btn btn-primary btn-submit-quote">
                    <i class='bx bx-plus'></i><span>New Assessment</span>
                </a>
            </div>
        </div>
        <div class="claimlinq-body">
            <tabs ref="claimlinq-assessments-table" @changed="tabChanged" cache-lifetime="0" :options="{useUrlFragment:true}">
                <tab v-if="!isUserRoleAssessor" name="All" id="assessments-all" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="computedAllAssessments"
                                :fields="fieldsAll"
                                :filter="filter"
                                @filtered="onAllAssessmentsFiltered"
                                :filter-function="filterForTable"
                                :current-page="assessmentsAllCurrentPage"
                                :per-page="assessmentsPerPage"
                                :sort-desc="computedSortDescForAssessmentsAll"
                                :sort-by="computedSortByForAssessmentsAll"
                                @sort-changed="setSortByAndSortDescForAssessmentsAll"
                                @row-clicked="clickAssessmentRow"
                        >
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template v-slot:cell(dateReceived)="data">
                                {{ data.item.dateReceived | formatDatetime }}
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notificationOnTable
                                        :notifications="data.item.notifications">
                                </notificationOnTable>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                        :role="'assessments'"
                        :arrayOfSomeone="gettersAllAssessments"
                        :arrayOfSomeoneFiltered="computedAllAssessmentsFiltered"
                        :currentPage="assessmentsAllCurrentPage"
                        @changedCurrentPage="value => $store.commit('assessment/setAssessmentsAllCurrentPage', value)"
                        :perPage="assessmentsPerPage"
                        :perPageForMultipleTables="computedPerPage"
                        @changedPerPage="value => $store.commit('assessment/setAssessmentsPerPage', value)"
                    >
                  </block-pagination>

                </tab>
                <tab name="New" id="assessments-new" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="computedNewAssessments"
                                :fields="fields"
                                :filter="filter"
                                :filter-function="filterForTable"
                                @filtered="onNewAssessmentsFiltered"
                                :current-page="assessmentsNewCurrentPage"
                                :per-page="assessmentsPerPage"
                                :sort-desc="computedSortDescForAssessmentsNew"
                                :sort-by="computedSortByForAssessmentsNew"
                                @sort-changed="setSortByAndSortDescForAssessmentsNew"
                                @row-clicked="clickAssessmentRow"
                        >
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template v-slot:cell(dateReceived)="data">
                                {{ data.item.dateReceived | formatDatetime }}
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notificationOnTable
                                        :notifications="data.item.notifications">
                                </notificationOnTable>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                        :role="'assessments'"
                        :arrayOfSomeone="computedNewAssessments2"
                        :arrayOfSomeoneFiltered="computedNewAssessmentsFiltered"
                        :currentPage="assessmentsNewCurrentPage"
                        @changedCurrentPage="value => $store.commit('assessment/setAssessmentsNewCurrentPage', value)"
                        :perPage="assessmentsPerPage"
                        :perPageForMultipleTables="computedPerPage"
                        @changedPerPage="value => $store.commit('assessment/setAssessmentsPerPage', value)"
                    >
                    </block-pagination>

                </tab>
                <tab v-if="!isUserRoleAssessor" name="Processing" id="assessments-processing" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="computedProcessingAssessments"
                                :fields="fields"
                                :filter="filter"
                                :current-page="assessmentsProcessingCurrentPage"
                                :per-page="assessmentsPerPage"
                                @filtered="onProcessingAssessmentsFiltered"
                                :filter-function="filterForTable"
                                :sort-desc="computedSortDescForAssessmentsProcessing"
                                :sort-by="computedSortByForAssessmentsProcessing"
                                @sort-changed="setSortByAndSortDescForAssessmentsProcessing"
                                @row-clicked="clickAssessmentRow"
                        >
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template v-slot:cell(dateReceived)="data">
                                {{ data.item.dateReceived | formatDatetime }}
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notificationOnTable
                                        :notifications="data.item.notifications">
                                </notificationOnTable>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                        :role="'assessments'"
                        :arrayOfSomeone="computedProcessingAssessments2"
                        :arrayOfSomeoneFiltered="computedProcessingAssessmentsFiltered"
                        :currentPage="assessmentsProcessingCurrentPage"
                        @changedCurrentPage="value => $store.commit('assessment/setAssessmentsProcessingCurrentPage', value)"
                        :perPage="assessmentsPerPage"
                        :perPageForMultipleTables="computedPerPage"
                        @changedPerPage="value => $store.commit('assessment/setAssessmentsPerPage', value)"
                    >
                    </block-pagination>

                </tab>
                <tab v-if="!isUserRoleAssessor" name="W/Recoveries" id="assessments-wrecoveries" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="computedWRecoveriesAssessments"
                                :fields="fieldsWRecoveries"
                                :filter="filter"
                                @filtered="onWRecoveriesAssessmentsFiltered"
                                :current-page="assessmentsWRecoveriesCurrentPage"
                                :per-page="assessmentsPerPage"
                                :filter-function="filterForTable"
                                :sort-desc="computedSortDescForAssessmentsWRecoveries"
                                :sort-by="computedSortByForAssessmentsWRecoveries"
                                @sort-changed="setSortByAndSortDescForAssessmentsWRecoveries"
                                @row-clicked="clickAssessmentRow"
                        >
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template v-slot:cell(dateReceived)="data">
                                {{ data.item.dateReceived | formatDatetime }}
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notificationOnTable
                                        :notifications="data.item.notifications">
                                </notificationOnTable>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                        :role="'assessments'"
                        :arrayOfSomeone="computedWRecoveriesAssessments2"
                        :arrayOfSomeoneFiltered="computedWRecoveriesAssessmentsFiltered"
                        :currentPage="assessmentsWRecoveriesCurrentPage"
                        @changedCurrentPage="value => $store.commit('assessment/setAssessmentsWRecoveriesCurrentPage', value)"
                        :perPage="assessmentsPerPage"
                        :perPageForMultipleTables="computedPerPage"
                        @changedPerPage="value => $store.commit('assessment/setAssessmentsPerPage', value)"
                    >
                    </block-pagination>

                </tab>

                <tab v-if="!isUserRoleAssessor" name="On-Hold" id="assessments-on-hold" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="computedOnHoldAssessments"
                                :fields="fieldsWRecoveries"
                                :filter="filter"
                                @filtered="onOnHoldAssessmentsFiltered"
                                :filter-function="filterForTable"
                                :current-page="assessmentsOnHoldCurrentPage"
                                :per-page="assessmentsPerPage"
                                :sort-desc="computedSortDescForAssessmentsOnHold"
                                :sort-by="computedSortByForAssessmentsOnHold"
                                @sort-changed="setSortByAndSortDescForAssessmentsOnHold"
                                @row-clicked="clickAssessmentRow"
                        >
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template v-slot:cell(dateReceived)="data">
                                {{ data.item.dateReceived | formatDatetime }}
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notificationOnTable
                                        :notifications="data.item.notifications">
                                </notificationOnTable>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                        :role="'assessments'"
                        :arrayOfSomeone="computedOnHoldAssessments2"
                        :arrayOfSomeoneFiltered="computedOnHoldAssessmentsFiltered"
                        :currentPage="assessmentsOnHoldCurrentPage"
                        @changedCurrentPage="value => $store.commit('assessment/setAssessmentsOnHoldCurrentPage', value)"
                        :perPage="assessmentsPerPage"
                        :perPageForMultipleTables="computedPerPage"
                        @changedPerPage="value => $store.commit('assessment/setAssessmentsPerPage', value)"
                    >
                    </block-pagination>

                </tab>
                <tab v-if="!isUserRoleAssessor" name="Completed" id="assessments-completed" :class-name="'tab-content-table'">
                    <div class="use-pagination-block-bottom">
                        <b-table
                                responsive
                                hover
                                :items="computedCompletedAssessments"
                                :fields="fieldsWRecoveries"
                                :filter="filter"
                                @filtered="onWRecoveriesAssessmentsFiltered"
                                :filter-function="filterForTable"
                                :current-page="assessmentsCompletedCurrentPage"
                                :per-page="assessmentsPerPage"
                                :sort-desc="computedSortDescForAssessmentsCompleted"
                                :sort-by="computedSortByForAssessmentsCompleted"
                                @sort-changed="setSortByAndSortDescForAssessmentsCompleted"
                                @row-clicked="clickAssessmentRow"
                        >
                            <template v-slot:head()="data">
                                <div v-if="!data.field.sortable">{{data.label}}</div>
                                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
                            </template>
                            <template v-slot:cell(dateReceived)="data">
                                {{ data.item.dateReceived | formatDatetime }}
                            </template>
                            <template v-slot:cell(notifications)="data">
                                <notificationOnTable
                                        :notifications="data.item.notifications">
                                </notificationOnTable>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                        :role="'assessments'"
                        :arrayOfSomeone="computedCompletedAssessments2"
                        :arrayOfSomeoneFiltered="computedCompletedAssessmentsFiltered"
                        :currentPage="assessmentsCompletedCurrentPage"
                        @changedCurrentPage="value => $store.commit('assessment/setAssessmentsCompletedCurrentPage', value)"
                        :perPage="assessmentsPerPage"
                        :perPageForMultipleTables="computedPerPage"
                        @changedPerPage="value => $store.commit('assessment/setAssessmentsPerPage', value)"
                    >
                    </block-pagination>

                </tab>

                <template slot="nav-item-right">
                    <div class="tabs-nav-right-search">
                        <search-filter
                                type="assessments"
                                v-model="searchFilter"
                                :filters="computedSearchFilters"
                                :filterValue="filter"
                                @change="onSearchFilterChange"
                                :debug="false"
                                height="41px"
                        />
<!--                         <b-form-input v-model="filter" type="text" class="search-input" placeholder="Search or Filter results" debounce="100"></b-form-input>-->
                    </div>
                    <div class="tabs-nav-right-btn ml-4">
                        <a v-if="isAssessmentsCompany" @click="clickNewAssessment" class="btn btn-primary btn-submit-quote">
                            <i class='bx bx-plus'></i><span>New Assessment</span>
                        </a>
                    </div>
                </template>
            </tabs>
        </div>
    </div>
</template>
<script>
    /*eslint-disable */
    import {mapActions, mapGetters} from 'vuex';
    import notificationOnTable from "@/components/notification-on-table.vue";
    //import dayjs from "dayjs";
    import SearchFilter, {Filter} from '@/components/search-filter/search-filter.vue';
    import _ from 'lodash';
    import BlockPagination from '../../components/utility/block-pagination';

    export default {
        name: 'assessments',
        data: function () {
            return {
                intervalUpdateAssessments: null,
                currentTab: '',
                filter: '',
                searchFilter: '',
                searchFilterObj: {},
                fields: [
                    {label: "Assessment Nbr", key: "number", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Policy Nbr", key: "policyNumber", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Vehicle Owner", key: "vehicleOwnerName", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Car Rego", key: "rego", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Make", key: "make", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Model", key: "model", sortable: true, tdClass: 'clickable', thClass: ''},
                    {label: "Repairer", key: "repairerName", sortable: true, tdClass: 'clickable', thClass: ''},
                    {label: "Assessor", key: "assessorName", sortable: true, tdClass: 'clickable', thClass: ''},
                    {label: "Notifications", key: "notifications", sortable: false, tdClass: 'clickable ', thClass: ''},
                    {label: "Date Created", key: "dateReceived", sortable: true, tdClass: 'clickable ', thClass: ''},
                ],
                fieldsAll: [
                    {label: "Assessment Nbr", key: "number", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Policy Nbr", key: "policyNumber", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Vehicle Owner", key: "vehicleOwnerName", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Car Rego", key: "rego", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Make", key: "make", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Model", key: "model", sortable: true, tdClass: 'clickable', thClass: ''},
                    {label: "Repairer", key: "repairerName", sortable: true, tdClass: 'clickable', thClass: ''},
                    {label: "Assessor", key: "assessorName", sortable: true, tdClass: 'clickable', thClass: ''},
                    {label: "Notifications", key: "notifications", sortable: false, tdClass: 'clickable ', thClass: ''},
                    {label: "Date Created", key: "dateReceived", sortable: true, tdClass: 'clickable ', thClass: ''},
                    //{label: "Status", key: "status", sortable: true, tdClass: 'clickable number', thClass: ''},
                ],
                fieldsWRecoveries: [
                    {label: "Assessment Nbr", key: "number", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Policy Nbr", key: "policyNumber", sortable: true, tdClass: 'clickable number', thClass: ''},
                    {label: "Vehicle Owner", key: "vehicleOwnerName", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Car Rego", key: "rego", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Make", key: "make", sortable: true, tdClass: 'clickable ', thClass: ''},
                    {label: "Model", key: "model", sortable: true, tdClass: 'clickable', thClass: ''},
                    {label: "Repairer", key: "repairerName", sortable: true, tdClass: 'clickable', thClass: ''},
                    {label: "Legal Firm", key: "legalFirmName", sortable: true, tdClass: 'clickable', thClass: ''},
                    {label: "Assessor", key: "assessorName", sortable: true, tdClass: 'clickable', thClass: ''},
                    {label: "Notifications", key: "notifications", sortable: false, tdClass: 'clickable ', thClass: ''},
                    {label: "Date Created", key: "dateReceived", sortable: true, tdClass: 'clickable ', thClass: ''},
                ],
                assessmentsAllFiltered: [],
                assessmentsNewFiltered: [],
                assessmentsProcessingFiltered: [],
                assessmentsWRecoveriesFiltered: [],
                assessmentsOnHoldFiltered: [],
                assessmentsCompletedFiltered: [],
            };
        },
        methods: {
            ...mapActions({
                //  getAllAssessments: 'claim/getAllAssessments',
                setFilter: 'assessment/setFilter',
            }),
            setSortByAndSortDescForAssessmentsAll(e) {
              this.$store.commit('assessment/setSortByForAssessmentsAll', e.sortBy);
              this.$store.commit('assessment/setSortDescForAssessmentsAll', e.sortDesc);
            },
            setSortByAndSortDescForAssessmentsNew(e) {
              this.$store.commit('assessment/setSortByForAssessmentsNew', e.sortBy);
              this.$store.commit('assessment/setSortDescForAssessmentsNew', e.sortDesc);
            },
            setSortByAndSortDescForAssessmentsProcessing(e) {
              this.$store.commit('assessment/setSortByForAssessmentsProcessing', e.sortBy);
              this.$store.commit('assessment/setSortDescForAssessmentsProcessing', e.sortDesc);
            },
            setSortByAndSortDescForAssessmentsWRecoveries(e) {
              this.$store.commit('assessment/setSortByForAssessmentsWRecoveries', e.sortBy);
              this.$store.commit('assessment/setSortDescForAssessmentsWRecoveries', e.sortDesc);
            },
            setSortByAndSortDescForAssessmentsOnHold(e) {
              this.$store.commit('assessment/setSortByForAssessmentsOnHold', e.sortBy);
              this.$store.commit('assessment/setSortDescForAssessmentsOnHold', e.sortDesc);
            },
            setSortByAndSortDescForAssessmentsCompleted(e) {
              this.$store.commit('assessment/setSortByForAssessmentsCompleted', e.sortBy);
              this.$store.commit('assessment/setSortDescForAssessmentsCompleted', e.sortDesc);
            },
            onAllAssessmentsFiltered(fi) {
                this.assessmentsAllFiltered = fi;
            },
            onNewAssessmentsFiltered(fi) {
                this.assessmentsNewFiltered = fi;
            },
            onProcessingAssessmentsFiltered(fi) {
                this.assessmentsProcessingFiltered = fi;
            },
            onWRecoveriesAssessmentsFiltered(fi) {
                this.assessmentsWRecoveriesFiltered = fi;
            },
            onOnHoldAssessmentsFiltered(fi) {
                this.assessmentsOnHoldFiltered = fi;
            },
            onCompletedAssessmentsFiltered(fi) {
                this.assessmentsCompletedFiltered = fi;
            },
            filterForTable(row, filter) {
              if (row.assessorName && row.assessorName.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.number && row.number.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.make && row.make.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.model && row.model.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.rego && row.rego.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.vehicleOwnerName && row.vehicleOwnerName.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.repairerName && row.repairerName.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.legalRef && row.legalRef.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.legalFirmName && row.legalFirmName.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.policyNumber && row.policyNumber.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              } else if (row.dateReceived && row.dateReceived.toLowerCase().includes(filter.toLowerCase())) {
                return true;
              }
              return false;
            },
            clickNewAssessment() {
                this.$router.push({name: 'AssessmentNewForAssessmentCompany'});
            },
            tabChanged(selectedTab) {
                //console.log('Tab changed to:' + selectedTab.tab.name);
                this.currentTab = selectedTab.tab.name;
            },
            changeAssessmentsPage: function () {

            },
            clickAssessmentRow: function (item, index, event) {
                this.$router.push({name: 'AssessmentView', params: {assessmentId: item.id}});
                return;
            },
            clickSubmitQuote() {
                // console.log("111", this.$refs['claimlinq-assessments-table']);
                // console.log("222", this.$refs['claimlinq-assessments-table'].activeTabHash);
                //if (this.$refs['claimlinq-table'] && this.$refs['claimlinq-table'].activeTabHash && this.$refs['claimlinq-table'].activeTabHash == "#received") {
                //    this.$router.push('/r/rfq/add/received');
                //} else {
                //    this.$router.push({name: 'RepairerRFQAdd'});
                //}
                return;
            },
            tableTabChanged: function () {

            },
            assessmentsCurrentPage: function () {

            },
            onAllCardsFiltered: function () {

            },
            onSearchFilterChange(filters) {
                this.searchFilterObj = filters;
                if (this.searchFilterObj && this.searchFilterObj.search) {
                    this.$set(this, 'filter', this.searchFilterObj.search)
                } else {
                    this.$set(this, 'filter', '')
                }
                console.log('onSearchFilterChange', filters);
            }
        },
        beforeDestroy() {
          clearInterval(this.intervalUpdateAssessments);
        },
        mounted() {
            this.$nextTick(() => {
                //console.log("claimlinq-assessments-table", this.$refs['claimlinq-assessments-table']);
                //console.log("activeTabHash.claimlinq-assessments-table", this.$refs['claimlinq-assessments-table'].activeTabHash);
                if (this.$route.hash) {
                    let hash = this.$route.hash;
                    this.$refs['claimlinq-assessments-table'].selectTab(hash);
                }
            });
            if (!this.filter) this.filter = this.gettersFilter;

            if (this.gettersFilter) this.filter = this.gettersFilter;

            this.intervalUpdateAssessments = setInterval(() => {
              if (this.assessmentsAllFiltered.length !== this.gettersAllAssessments.length && !_.isEmpty(this.gettersAllAssessments)) {
                this.assessmentsAllFiltered = this.gettersAllAssessments;
                this.assessmentsNewFiltered = _.filter(this.computedAllAssessments, function (item) {
                  return (item.status == "New");
                });
                this.assessmentsProcessingFiltered = _.filter(this.computedAllAssessments, function (item) {
                  return (item.status == "Processing");
                });
                this.assessmentsWRecoveriesFiltered = _.filter(this.computedAllAssessments, function (item) {
                  return (item.status == "WRecoveries");
                });
                this.assessmentsOnHoldFiltered = _.filter(this.computedAllAssessments, function (item) {
                  return (item.status == "OnHold");
                });
                this.assessmentsCompletedFiltered = _.filter(this.computedAllAssessments, function (item) {
                  return (item.status == "Completed");
                });
                clearInterval(this.intervalUpdateAssessments);
              }
            }, 10);

        },
        computed: {
            ...mapGetters({
                gettersAllAssessments: 'assessment/getAllAssessments',
                gettersFilter: 'assessment/getFilter',
                isUserRoleAssessor: 'currentUser/isRoleAssessor',
                isUserRoleLawyer: 'currentUser/isRoleLawyer',
                isAssessmentsCompany: 'currentCompany/isAssessments',
                getterSortByForAssessmentsAll: 'assessment/getSortByForAssessmentsAll',
                getterSortDescForAssessmentsAll: 'assessment/getSortDescForAssessmentsAll',
                getterSortByForAssessmentsNew: 'assessment/getSortByForAssessmentsNew',
                getterSortDescForAssessmentsNew: 'assessment/getSortDescForAssessmentsNew',
                getterSortByForAssessmentsProcessing: 'assessment/getSortByForAssessmentsProcessing',
                getterSortDescForAssessmentsProcessing: 'assessment/getSortDescForAssessmentsProcessing',
                getterSortByForAssessmentsWRecoveries: 'assessment/getSortByForAssessmentsWRecoveries',
                getterSortDescForAssessmentsWRecoveries: 'assessment/getSortDescForAssessmentsWRecoveries',
                getterSortByForAssessmentsOnHold: 'assessment/getSortByForAssessmentsOnHold',
                getterSortDescForAssessmentsOnHold: 'assessment/getSortDescForAssessmentsOnHold',
                getterSortByForAssessmentsCompleted: 'assessment/getSortByForAssessmentsCompleted',
                getterSortDescForAssessmentsCompleted: 'assessment/getSortDescForAssessmentsCompleted',
                getterAssessmentsPerPage: 'assessment/getAssessmentsPerPage',
                getterAssessmentsAllCurrentPage: 'assessment/getAssessmentsAllCurrentPage',
                getterAssessmentsNewCurrentPage: 'assessment/getAssessmentsNewCurrentPage',
                getterAssessmentsProcessingCurrentPage: 'assessment/getAssessmentsProcessingCurrentPage',
                getterAssessmentsWRecoveriesCurrentPage: 'assessment/getAssessmentsWRecoveriesCurrentPage',
                getterAssessmentsOnHoldCurrentPage: 'assessment/getAssessmentsOnHoldCurrentPage',
                getterAssessmentsCompletedCurrentPage: 'assessment/getAssessmentsCompletedCurrentPage',
            }),
            assessmentsPerPage() {
              return this.getterAssessmentsPerPage;
            },
            assessmentsAllCurrentPage() {
              return this.getterAssessmentsAllCurrentPage;
            },
            assessmentsNewCurrentPage() {
              return this.getterAssessmentsNewCurrentPage;
            },
            assessmentsProcessingCurrentPage() {
              return this.getterAssessmentsProcessingCurrentPage;
            },
            assessmentsWRecoveriesCurrentPage() {
              return this.getterAssessmentsWRecoveriesCurrentPage;
            },
            assessmentsOnHoldCurrentPage() {
              return this.getterAssessmentsOnHoldCurrentPage;
            },
            assessmentsCompletedCurrentPage() {
              return this.getterAssessmentsCompletedCurrentPage;
            },
            computedSortByForAssessmentsAll() {
              return this.getterSortByForAssessmentsAll;
            },
            computedSortDescForAssessmentsAll() {
              return this.getterSortDescForAssessmentsAll;
            },
            computedSortByForAssessmentsNew() {
              return this.getterSortByForAssessmentsNew;
            },
            computedSortDescForAssessmentsNew() {
              return this.getterSortDescForAssessmentsNew;
            },
            computedSortByForAssessmentsProcessing() {
              return this.getterSortByForAssessmentsProcessing;
            },
            computedSortDescForAssessmentsProcessing() {
              return this.getterSortDescForAssessmentsProcessing;
            },
            computedSortByForAssessmentsWRecoveries() {
              return this.getterSortByForAssessmentsWRecoveries;
            },
            computedSortDescForAssessmentsWRecoveries() {
              return this.getterSortDescForAssessmentsWRecoveries;
            },
            computedSortByForAssessmentsOnHold() {
              return this.getterSortByForAssessmentsOnHold;
            },
            computedSortDescForAssessmentsOnHold() {
              return this.getterSortDescForAssessmentsOnHold;
            },
            computedSortByForAssessmentsCompleted() {
              return this.getterSortByForAssessmentsCompleted;
            },
            computedSortDescForAssessmentsCompleted() {
              return this.getterSortDescForAssessmentsCompleted;
            },
            computedPerPage() {
              return this.assessmentsPerPage;
            },
            computedAllAssessmentsFiltered() {

              let vm = this;
              let assessments = this.assessmentsAllFiltered;

              if (vm.searchFilterObj.vehicleOwnerName && vm.searchFilterObj.vehicleOwnerName != '') {
                assessments = _.filter(assessments, function (item) {
                  return (item.vehicleOwnerName == vm.searchFilterObj.vehicleOwnerName);
                });
              }
              if (vm.searchFilterObj.rego && vm.searchFilterObj.rego != '') {
                assessments = _.filter(assessments, function (item) {
                  return (item.rego == vm.searchFilterObj.rego);
                });
              }
              if (vm.searchFilterObj.repairerName && vm.searchFilterObj.repairerName != '') {
                assessments = _.filter(assessments, function (item) {
                  return (item.repairerName == vm.searchFilterObj.repairerName);
                });
              }
              if (vm.searchFilterObj.legalRef && vm.searchFilterObj.legalRef != '') {
                assessments = _.filter(assessments, function (item) {
                  return (item.legalRef == vm.searchFilterObj.legalRef);
                });
              }
              if (vm.searchFilterObj.legalFirmName && vm.searchFilterObj.legalFirmName != '') {
                assessments = _.filter(assessments, function (item) {
                  return (item.legalFirmName == vm.searchFilterObj.legalFirmName);
                });
              }
              if (vm.searchFilterObj.date && vm.searchFilterObj.date.label && vm.searchFilterObj.date.endDate && vm.searchFilterObj.date.startDate) {
                assessments = _.filter(assessments, function (item) {
                  let d = new Date(item.dateReceived).getTime();
                  let s = new Date(vm.searchFilterObj.date.startDate).getTime();
                  let e = new Date(vm.searchFilterObj.date.endDate).getTime();
                  return d > s && d < e;
                });
              }
              return assessments;

            },
            computedNewAssessmentsFiltered() {
              return _.filter(this.computedAllAssessmentsFiltered, function (item) {
                return item.status === 'New';
              })
            },
            computedProcessingAssessmentsFiltered() {
              return _.filter(this.computedAllAssessmentsFiltered, function (item) {
                return item.status === 'Processing';
              })
            },
            computedWRecoveriesAssessmentsFiltered() {
              return _.filter(this.computedAllAssessmentsFiltered, function (item) {
                return item.status === 'WRecoveries';
              })
            },
            computedOnHoldAssessmentsFiltered() {
              return _.filter(this.computedAllAssessmentsFiltered, function (item) {
                return item.status === 'OnHold';
              })
            },
            computedCompletedAssessmentsFiltered() {
              return _.filter(this.computedAllAssessmentsFiltered, function (item) {
                return item.status === 'Completed';
              })
            },
            computedNewAssessments2() {
              return _.filter(this.gettersAllAssessments, function (item) {
                return item.status === 'New';
              })
            },
            computedProcessingAssessments2() {
              return _.filter(this.gettersAllAssessments, function (item) {
                return item.status === 'Processing';
              })
            },
            computedWRecoveriesAssessments2() {
              return _.filter(this.gettersAllAssessments, function (item) {
                return item.status === 'WRecoveries';
              })
            },
            computedOnHoldAssessments2() {
              return _.filter(this.gettersAllAssessments, function (item) {
                return item.status === 'OnHold';
              })
            },
            computedCompletedAssessments2() {
              return _.filter(this.gettersAllAssessments, function (item) {
                return item.status === 'Completed';
              })
            },
            computedAllAssessments() {
                let keys = _.keys(this.searchFilterObj);
                console.log('keys', keys);
                let vm = this;
                let assessments = this.gettersAllAssessments;

                if (vm.searchFilterObj.vehicleOwnerName && vm.searchFilterObj.vehicleOwnerName != '') {
                    assessments = _.filter(assessments, function (item) {
                        return (item.vehicleOwnerName == vm.searchFilterObj.vehicleOwnerName);
                    });
                }
                if (vm.searchFilterObj.rego && vm.searchFilterObj.rego != '') {
                    assessments = _.filter(assessments, function (item) {
                        return (item.rego == vm.searchFilterObj.rego);
                    });
                }
                if (vm.searchFilterObj.repairerName && vm.searchFilterObj.repairerName != '') {
                    assessments = _.filter(assessments, function (item) {
                        return (item.repairerName == vm.searchFilterObj.repairerName);
                    });
                }
                if (vm.searchFilterObj.legalRef && vm.searchFilterObj.legalRef != '') {
                    assessments = _.filter(assessments, function (item) {
                        return (item.legalRef == vm.searchFilterObj.legalRef);
                    });
                }
                if (vm.searchFilterObj.legalFirmName && vm.searchFilterObj.legalFirmName != '') {
                    assessments = _.filter(assessments, function (item) {
                        return (item.legalFirmName == vm.searchFilterObj.legalFirmName);
                    });
                }
                if (vm.searchFilterObj.date && vm.searchFilterObj.date.label && vm.searchFilterObj.date.endDate && vm.searchFilterObj.date.startDate) {
                  assessments = _.filter(assessments, function (item) {
                    let d = new Date(item.dateReceived).getTime();
                    let s = new Date(vm.searchFilterObj.date.startDate).getTime();
                    let e = new Date(vm.searchFilterObj.date.endDate).getTime();
                    return d > s && d < e;
                  });
                }
                if (vm.searchFilterObj.assessorName && vm.searchFilterObj.assessorName != '') {
                  assessments = _.filter(assessments, function (item) {
                    return (item.assessorName == vm.searchFilterObj.assessorName);
                  });
                }
                return assessments;
            },
            computedNewAssessments() {
                let vm = this;
                let assessments = _.filter(this.computedAllAssessments, function (item) {
                    return (item.status == "New");
                });
                return assessments;
            },
            computedProcessingAssessments() {
                let vm = this;
                let assessments = _.filter(this.computedAllAssessments, function (item) {
                    return (item.status == "Processing");
                });
                return assessments;
            },
            computedWRecoveriesAssessments() {
                let vm = this;
                let assessments = _.filter(this.computedAllAssessments, function (item) {
                    return (item.status == "WRecoveries");
                });
                return assessments;
            },
            computedOnHoldAssessments() {
                let vm = this;
                let assessments = _.filter(this.computedAllAssessments, function (item) {
                    return (item.status == "OnHold");
                });
                return assessments;
            },
            computedCompletedAssessments() {
                let vm = this;
                let assessments = _.filter(this.computedAllAssessments, function (item) {
                    return (item.status == "Completed");
                });
                return assessments;
            },
            computedSearchFilters() {
                if (this.gettersAllAssessments.length > 0) {
                    let regos = {};
                    let vehicleOwnerNames = {};
                    let repairerNames = {};
                    let legalRefs = {};
                    let legalFirmNames = {};
                    let assessors = {};

                    this.gettersAllAssessments.forEach(assessment => {
                        if (assessment.rego) {
                            regos[assessment.rego] = {label: assessment.rego};
                        }
                        if (assessment.vehicleOwnerName && assessment.vehicleOwnerName !== " ") {
                            vehicleOwnerNames[assessment.vehicleOwnerName] = {label: assessment.vehicleOwnerName};
                        }
                        if (assessment.repairerName && assessment.repairerName != " ") {
                            repairerNames[assessment.repairerName] = {label: assessment.repairerName};
                        }
                        if (assessment.legalRef) {
                            legalRefs[assessment.legalRef] = {label: assessment.legalRef};
                        }
                        if (assessment.legalFirmName) {
                            legalFirmNames[assessment.legalFirmName] = {label: assessment.legalFirmName};
                        }
                        if (assessment.assessorName) {
                          assessors[assessment.assessorName] = {label: assessment.assessorName};
                        }
                    });

                    regos = Object.values(regos);
                    vehicleOwnerNames = Object.values(vehicleOwnerNames);
                    repairerNames = Object.values(repairerNames);
                    legalRefs = Object.values(legalRefs);
                    legalFirmNames = Object.values(legalFirmNames);
                    assessors = Object.values(assessors);

                    // Create date template
                    const date = [
                        {
                            id: 'date',
                            label: "Date",
                            resultObjectOnly: true,
                            type: "Date",
                            iconClass: "bx-calendar"
                        }
                    ];

                    // Create searchFilter filters
                    return [
                        {
                            id: 'rego',
                            label: 'Rego',
                            iconClass: 'bx-car',
                            options: [
                                regos,
                                // insuredNames,
                                // otherParties,
                                // repairerNames,
                                // rentalAgrNbrs,
                                date,
                            ]
                        },
                        {
                            id: 'vehicleOwnerName',
                            label: 'Vehicle Owner',
                            iconClass: 'bx-user',
                            options: [
                                vehicleOwnerNames,
                                date,
                            ]
                        },
                        {
                          id: 'assessorName',
                          label: 'Assessor',
                          iconClass: 'bx-user',
                          options: [
                            assessors
                          ]
                        },
                        {
                            id: 'repairerName',
                            label: 'Repairer Name',
                            iconClass: 'bxs-user-badge',
                            options: [
                                repairerNames,
                                date,
                            ]
                        },
                        {
                            id: 'legalRef',
                            label: 'Legal Ref',
                            iconClass: 'bx-box',
                            options: [
                                legalRefs,
                                date,
                            ]
                        },
                        {
                            id: 'legalFirmName',
                            label: 'Legal Firm',
                            iconClass: 'bx-store',
                            options: [
                                legalFirmNames,
                                date,
                            ]
                        }
                    ];
                } else {
                    return [];
                }
            }
        },
        watch: {
            filter(val) {
                console.log('updateFilter', val);
                this.setFilter(val);
            },
        },
        components: {
            BlockPagination,
            notificationOnTable,
            SearchFilter,
        }
    };
</script>

<style>
    .claimlinq-body td.order-cost {
        padding-right: 25px;
    }

    .V3 .claimlinq-page .b-table td {
        color: #1C1F39;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
    }

    .V3 .claimlinq-page .b-table td.number {
        font-weight: bold;
    }

    .V3 .btn-submit-quote {
        vertical-align: middle;
    }

    .V3 .btn-submit-quote i {
        font-size: 17px;
        vertical-align: middle;
    }

    .V3 .btn-submit-quote span {
        vertical-align: middle;
    }

</style>
