<template>
    <span>
<!--eslint-disable-->
        <div class="row">
            <div class="col-12 col-lg pr-0 col-block">
                <div class="card">
                    <div class="card-header bg-navyblue">
                        <strong>Assessment</strong>
                    </div>
                    <div class="card-block bg-white">
                        <div class="form-group row">
                            <label class="col-sm-4 col-4 col-form-label">Reference Number</label>
                            <div class="col-sm-8 col-8">
                                <input type="text" v-model="assessmentTab.assessment.number" class="form-control" :readonly="isUserRoleLawyer">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-4 col-form-label">Policy Number</label>
                            <div class="col-sm-8 col-8">
                                <input type="text" v-model="assessmentTab.assessment.policyNumber" class="form-control" :readonly="viewerIsAssessor || isUserRoleLawyer">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-4 col-form-label">Acting on Behalf</label>
                            <div class="col-sm-8 col-8">
                                <input type="text" v-model="assessmentTab.assessment.actingOnBehalf" class="form-control" :readonly="isUserRoleLawyer">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-4 col-form-label">Inspected At</label>
                            <div class="col-sm-8 col-8">
                                <input type="text" v-model="assessmentTab.assessment.inspectedAt" class="form-control" :readonly="isUserRoleLawyer">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-4 col-form-label">Insured/Third Party</label>
                            <div class="col-sm-8 col-8">
                                <input type="text" v-model="assessmentTab.assessment.insuredThirdParty" class="form-control" :readonly="isUserRoleLawyer">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-4 col-form-label">Sum Insured</label>
                            <div class="col-sm-8 col-8">
                                   <number-formatter v-model="assessmentTab.assessment.sumInsured" class="form-control numeric" format="$0,0.00" :readonly="isUserRoleLawyer"/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-4 col-form-label">PAV</label>
                            <div class="col-sm-8 col-8">
                                <input type="text" v-model="assessmentTab.assessment.PAV" class="form-control" :readonly="isUserRoleLawyer">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div class="col-12 col-lg-8 col-block">
                  <div class="card">
                    <div class="card-header bg-navyblue">
                         <strong>Vehicle Condition</strong>
                    </div>
                    <div class="card-block bg-white">
                        <div class="row">
                           <div class="col-12 col-lg col-block">
                              <div class="form-group row">
                                  <label for="body" class="col-sm-4 col-4 col-form-label">Rego</label>
                                  <div class="col-sm-8 col-8">
                                      <input type="text" v-model="assessmentTab.vehicle.rego" class="form-control" :readonly="isUserRoleLawyer">
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label for="body" class="col-sm-4 col-4 col-form-label">DOM</label>
                                  <div class="col-sm-8 col-8">
                                      <input type="text" v-model="assessmentTab.vehicle.dom" class="form-control" :readonly="isUserRoleLawyer">
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label for="body" class="col-sm-4 col-4 col-form-label">Make</label>
                                  <div class="col-sm-8 col-8">
                                      <input type="text" v-model="assessmentTab.vehicle.make" class="form-control" :readonly="isUserRoleLawyer">
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label for="body" class="col-sm-4 col-4 col-form-label">Model</label>
                                  <div class="col-sm-8 col-8">
                                      <input type="text" v-model="assessmentTab.vehicle.model" class="form-control" :readonly="isUserRoleLawyer">
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label for="body" class="col-sm-4 col-4 col-form-label">Series</label>
                                  <div class="col-sm-8 col-8">
                                      <input type="text" v-model="assessmentTab.vehicle.series" class="form-control" :readonly="isUserRoleLawyer">
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label for="body" class="col-sm-4 col-4 col-form-label">Schedule Number</label>
                                  <div class="col-sm-8 col-8">
                                      <input type="text" v-model="scheduleNumber" class="form-control" maxlength="108" :readonly="isUserRoleLawyer">
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label for="body" class="col-sm-4 col-4 col-form-label">VIN</label>
                                  <div class="col-sm-8 col-8">
                                      <input type="text" v-model="assessmentTab.vehicle.vin" class="form-control" :readonly="isUserRoleLawyer">
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label for="body" class="col-sm-4 col-4 col-form-label">Colour</label>
                                  <div class="col-sm-8 col-8">
                                      <input type="text" v-model="assessmentTab.vehicle.colour" class="form-control" :readonly="isUserRoleLawyer">
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label for="body" class="col-sm-4 col-4 col-form-label">Odometer</label>
                                  <div class="col-sm-8 col-8">
                                      <input type="text" v-model="assessmentTab.vehicle.odometer" class="form-control" :readonly="isUserRoleLawyer">
                                  </div>
                              </div>
                              <div class="form-group row">
                                  <label class="col-sm-4 col-4 col-form-label">Engine</label>
                                  <div class="col-sm-8 col-8">
                                      <input type="text" v-model="assessmentTab.vehicle.engine" class="form-control" :readonly="isUserRoleLawyer">
                                  </div>
                              </div>
                           </div>
                <div class="col-12 col-lg-6 col-block">
                               <div class="form-group row">
                                    <label class="col-sm-4 col-4 col-form-label">Rego Status</label>
                                   <div class="col-sm-8 col-8">
                                       <input type="text" v-model="assessmentTab.vehicle.regoStatus" class="form-control" :readonly="isUserRoleLawyer">
                                   </div>
                               </div>
                               <div class="form-group row">
                                    <label class="col-sm-4 col-4 col-form-label">General Condition</label>
                                   <div class="col-sm-8 col-8">
                                       <input type="text" v-model="assessmentTab.vehicle.generalCondition" class="form-control" :readonly="isUserRoleLawyer">
                                   </div>
                               </div>
                               <div class="form-group row">
                                    <label class="col-sm-4 col-4 col-form-label">Interior Condition</label>
                                   <div class="col-sm-8 col-8">
                                       <input type="text" v-model="assessmentTab.vehicle.interiorCondition" class="form-control" :readonly="isUserRoleLawyer">
                                   </div>
                               </div>
                               <div class="form-group row">
                                    <label class="col-sm-4 col-4 col-form-label">Exterior Condition</label>
                                   <div class="col-sm-8 col-8">
                                       <input type="text" v-model="assessmentTab.vehicle.exteriorCondition" class="form-control" :readonly="isUserRoleLawyer">
                                   </div>
                               </div>
                               <div class="form-group row">
                                    <label class="col-sm-4 col-4 col-form-label">Transmission</label>
                                   <div class="col-sm-8 col-8">
                                       <input type="text" v-model="assessmentTab.vehicle.transmission" class="form-control" :readonly="isUserRoleLawyer">
                                   </div>
                               </div>
                               <div class="form-group row">
                                    <label class="col-sm-4 col-4 col-form-label">Steering</label>
                                   <div class="col-sm-8 col-8">
                                       <input type="text" v-model="assessmentTab.vehicle.steering" class="form-control" :readonly="isUserRoleLawyer">
                                   </div>
                               </div>
                               <div class="form-group row">
                                    <label class="col-sm-4 col-4 col-form-label">Tyres</label>
                                   <div class="col-sm-8 col-8">
                                       <input type="text" v-model="assessmentTab.vehicle.tyres" class="form-control" :readonly="isUserRoleLawyer">
                                   </div>
                               </div>
                               <div class="form-group row">
                                    <label class="col-sm-4 col-4 col-form-label">Brakes</label>
                                   <div class="col-sm-8 col-8">
                                       <input type="text" v-model="assessmentTab.vehicle.brakes" class="form-control" :readonly="isUserRoleLawyer">
                                   </div>
                               </div>
                               <div class="form-group row">
                                    <label class="col-sm-4 col-4 col-form-label">Optional Extras</label>
                                   <div class="col-sm-8 col-8">
                                       <textarea type="text" v-model="assessmentTab.vehicle.optionalExtras" class="form-control" :readonly="isUserRoleLawyer"></textarea>
                                   </div>
                               </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-12 pr-0 col-block">
                <div class="card">
                    <div class="card-header bg-navyblue">
                        <strong>Assessment Information</strong>
                    </div>
                    <div class="card-block bg-white">
                        <div class="row">
                            <div class="col-12 col-lg-4 col-block">
                                <div class="pricing-table-wrapper">
                                    <table class="pricing-table no-border-top-bottom">
                                        <colgroup>
                                            <col class="tcol-30" style="width:250px;">
                                            <col class="quoted">
                                            <col class="assessed">
                                        </colgroup>
                                        <quoted-assessed v-for="estimate in allItems" :numberEstimate="allItems.length" :info="estimate.info" :itemslist="estimate.itemsList" :isDollarsMethodology="isDollarsMethodology">

                                        </quoted-assessed>
                                        <tbody>
                                            <tr>
                                                <td class="pricing-table__key text-align-right no-border no-border">
                                                    Subtotal
                                                </td>
                                                <td class="pricing-table__quoted">
                                                {{quotedSubtotal|formatMoney}}
                                                </td>
                                                <td class="pricing-table__assessed">
                                            {{assessedSubtotal|formatMoney}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="pricing-table__key text-align-right no-border">
                                                    GST
                                                </td>
                                                <td class="pricing-table__quoted">
                                                {{quotedGST|formatMoney}}
                                                </td>
                                                <td class="pricing-table__assessed">
                                                {{assessedGST|formatMoney}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="pricing-table__key text-align-right no-border">
                                                    <span class="font-black">TOTAL ESTIMATE</span>
                                                </td>
                                                <td class="pricing-table__quoted">
                                                    <span class="font-black">{{quotedEstimate|formatMoney}}</span>
                                                </td>
                                                <td class="pricing-table__assessed">
                                                    <span class="font-black">{{assessedEstimate|formatMoney}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="pricing-table__key text-align-right no-border">
                                                    Additional INCL GST
                                                </td>
                                                <td class="pricing-table__quoted">
                                                    {{quotedAdditional|formatMoney}}
                                                </td>
                                                <td class="pricing-table__assessed">
                                                    {{assessedAdditional|formatMoney}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="pricing-table__key text-align-right no-border">
                                                    Less Excess (Total)
                                                </td>
                                                <td class="pricing-table__quoted">
                                                {{quotedExcess|formatMoney}}
                                                </td>
                                                <td class="pricing-table__assessed">
                                                {{assessedExcess|formatMoney}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="pricing-table__key text-align-right no-border">
                                                    <span class="font-black">TOTAL REPAIR COST</span>
                                                </td>
                                                <td class="pricing-table__quoted">
                                                    <span class="font-black">{{quotedTotalRepaierCost|formatMoney}}</span>
                                                </td>
                                                <td class="pricing-table__assessed">
                                                    <span class="font-black">{{assessedTotalRepaierCost|formatMoney}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="pricing-table__key text-align-right no-border">
                                                    <span class="font-black">TOTAL ADJ %</span>
                                                </td>
                                                <td class="pricing-table__quoted"></td>
                                                <td class="pricing-table__assessed" style="color: red">
                                                  <span class="font-black">{{totalADJPercent}}%</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-block">
                                <div class="form-group row">
                                     <label class="col-sm-4 col-4 col-form-label">Assessors Comments for Repairer</label>
                                     <div class="col-sm-8 col-8">
                                         <textarea type="text" v-model="assessmentTab.info.assessorCommentsForRepairer" class="form-control" rows="7" :readonly="isUserRoleLawyer"></textarea>
                                     </div>
                                </div>
                                 <div class="form-group row">
                                     <label class="col-sm-4 col-4 col-form-label">Assessors Comments for Insurer</label>
                                     <div class="col-sm-8 col-8">
                                         <textarea type="text" v-model="assessmentTab.info.assessorCommentsForInsurer" class="form-control" rows="7" :readonly="isUserRoleLawyer"></textarea>
                                     </div>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-block">
                                <div class="form-group row">
                                     <label class="col-sm-4 col-4 col-form-label">Authorised Repairer</label>
                                     <div class="col-sm-8 col-8">
                                          <input type="text" v-model="assessmentTab.info.repairerName" class="form-control" :readonly="isUserRoleLawyer">
                                    </div>
                                </div>
                                <div class="form-group row">
                                     <label class="col-sm-4 col-4 col-form-label">Repairer Address</label>
                                     <div class="col-sm-8 col-8">
                                          <input type="text" v-model="assessmentTab.info.repairerAddress" class="form-control" :readonly="isUserRoleLawyer">
                                    </div>
                                </div>
                                <div class="form-group row">
                                     <label class="col-sm-4 col-4 col-form-label">Repairer Email</label>
                                     <div class="col-sm-8 col-8">
                                          <input type="text" v-model="assessmentTab.info.repairerEmail" class="form-control" :readonly="isUserRoleLawyer">
                                    </div>
                                </div>
                                <div class="form-group row">
                                     <label class="col-sm-4 col-4 col-form-label">Quote Date</label>
                                     <div class="col-sm-8 col-8">
                                          <b-form-datepicker
                                                  v-model="assessmentTab.info.quoteDate"
                                                  today-button
                                                  reset-button
                                                  close-button
                                                  class="mb-2"
                                                  locale="en-GB"
                                                  :date-format-options="{  day: '2-digit', month: '2-digit', year: 'numeric'}"
                                                  v-b-tooltip.hover="{customClass: 'ps-tooltip', title: 'Select a Date', placement:'left'}"
                                                  :disabled="isUserRoleLawyer"
                                          ></b-form-datepicker>
                                    </div>
                                </div>
                                <div class="form-group row">
                                     <label class="col-sm-4 col-4 col-form-label">Quote Nbr</label>
                                     <div class="col-sm-8 col-8">
                                          <input type="text" v-model="assessmentTab.info.quoteNumber" class="form-control" :readonly="isUserRoleLawyer">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-4 col-4 col-form-label">Claim instruction</label>
                                    <div class="col-sm-8 col-8">
                                        <input type="text" v-model="assessmentTab.info.claimInstruction" class="form-control" :readonly="isUserRoleLawyer">
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    </span>
</template>
<script>
    /* eslint-disable */
    import NumberFormatter from '@/components/number-formatter';
    import Axios from "axios";
    import QuotedAssessed from "./assessment-tab/quoted-assessed";
    import {mapGetters} from 'vuex';
    import accounting from 'accounting';

    export default {
        name: 'assessment-tab',
        props: {
            info: {},
            assessmentTab: {
                type: Object,
                default: function () {
                    return {
                        assessment: {
                            number: "",
                            PAV: "",
                            actingOnBehalf: "",
                            quoteNumber: "",
                            quoteDate: "",
                            sumInsured: "",
                            insuredThirdParty: "",
                            inspectedAt: "",
                            claimInstruction: "",
                            policyNumber: "",
                            assessorId: "",
                            assessorName: "",
                            assessmentDate: "",
                        },
                        info: {},
                        vehicle: {},
                    };
                }
            },
            allItems: [Array, null],
            assessmentId: 0,
            policyNumber: String,
            additionalPrices: Object,
            viewerIsAssessor: true,
            isDollarsMethodology: {
              type: Boolean,
              default: false,
            },
        },
        data: function () {
            return {};
        },
        methods: {
            saveAssessment() {
                return;
                //let id = this.assessmentId;
                //Axios.post('/ir/assessment/' + id, this.info)
                //    .then((response) => {
                //
                //    })
                //    .catch((error) => {
                //
                //    });

            },
        },
        created() {
        },
        computed: {
            ...mapGetters({
                isUserRoleLawyer: 'currentUser/isRoleLawyer',
            }),
            totalADJPercent() {
                if (!this.isSomeItemsApproved)
                {
                    return "0.00";
                }
                if (this.quotedTotalRepaierCost > 0) {
                    return accounting.toFixed(Math.abs( this.quotedTotalRepaierCost-this.assessedTotalRepaierCost) / this.quotedTotalRepaierCost * 100, 2);
                } else {
                    return "0.00";
                }

            },
            scheduleNumber: {
                get() {
                    let a = [];
                    if (this.assessmentTab.vehicle.seriesScheduleNumber) {
                        a.push(this.assessmentTab.vehicle.seriesScheduleNumber);
                    }
                    if (this.assessmentTab.vehicle.seriesScheduleNumberDescription != null) {
                        a.push(this.assessmentTab.vehicle.seriesScheduleNumberDescription);
                    }
                    return a.join(' - ');
                },
                set(newVal) {
                    let a = newVal.split(' - ');
                    if (!a.length) return;
                    this.assessmentTab.vehicle.seriesScheduleNumber = a.shift();
                    this.assessmentTab.vehicle.seriesScheduleNumberDescription = a.join(' - ');
                    if (newVal.split(' - ').length < 2) {
                        this.assessmentTab.vehicle.seriesScheduleNumberDescription = null;
                    }
                },
            },
            quotedSubtotal() {
                let subtotal = 0;
                _.forEach(this.allItems, function (estimate) {
                    if (estimate && estimate.info) {
                        subtotal += estimate.info.quotedSubtotal;
                    }
                });
                subtotal = parseFloat(parseFloat(subtotal).toFixed(2));
                return subtotal;
            },
            assessedSubtotal() {
                let subtotal = 0;
                _.forEach(this.allItems, function (estimate) {
                    if (estimate && estimate.info) {
                        subtotal += estimate.info.assessedSubtotal;
                    }
                });
                subtotal = parseFloat(parseFloat(subtotal).toFixed(2));
//depraceted
                //Axios.post(`/ir/assessment/${this.assessmentId}`, {
                //    assessmentInfo: {
                //        assessment: {
                //            subtotal: subtotal
                //        }
                //    }
                //});
                return subtotal;
            },
            quotedGST() {
                let subtotal = this.quotedSubtotal * 0.1;
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            assessedGST() {
                let subtotal = this.assessedSubtotal * 0.1;
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            quotedEstimate() {
                let subtotal = this.quotedSubtotal + this.quotedGST;
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            assessedEstimate() {
                let subtotal = this.assessedSubtotal + this.assessedGST;
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            quotedAdditional() {
                let subtotal = this.additionalPrices.contribution;
                return parseFloat((parseFloat(subtotal) * 1.1).toFixed(2));
            },
            assessedAdditional() {
                let subtotal = this.additionalPrices.contribution;
                return parseFloat((parseFloat(subtotal) * 1.1).toFixed(2));
            },
            quotedExcess() {
                let subtotal = this.additionalPrices.excess;
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            assessedExcess() {
                let subtotal = this.additionalPrices.excess;
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            quotedTotalRepaierCost() {
                let subtotal = this.quotedEstimate + this.quotedAdditional - this.quotedExcess;
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            assessedTotalRepaierCost() {
                let subtotal = this.assessedEstimate + this.assessedAdditional - this.assessedExcess;
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            isSomeItemsApproved() {
                return _.some(this.allItems, function (estimate) {
                    return _.some(estimate.itemsList, function (items) {
                        return _.some(items, function (item) {
                            return (item && item.approveStatus != 'none');
                        });

                    });
                });
            },
        },
        components: {
            NumberFormatter,
            QuotedAssessed,
        },

    };
</script>
