<template>
    <tbody>
    <tr>
        <td class="pricing-table__key no-border-top">
            <span v-if="info.type == 'estimate'">ESTIMATE</span>
            <span v-else>SUPPLEMENT</span>
        </td>
        <td class="pricing-table__quoted no-border-top">
            <span v-if="numberEstimate<2 || info.type == 'estimate'" class="font-green">QUOTED</span>
        </td>
        <td class="pricing-table__assessed no-border-top">
            <span v-if="numberEstimate<2 || info.type == 'estimate'" class="font-green">ASSESSED</span>
        </td>
    </tr>
    <tr>
        <td class="pricing-table__key">
            RR Labour
        </td>
        <td class="pricing-table__quoted">
            {{quotedRR|formatMoney}}
        </td>
        <td class="pricing-table__assessed">
            {{assessedRR|formatMoney}}
        </td>
    </tr>
    <tr>
        <td class="pricing-table__key">
            Repair Labour
        </td>
        <td class="pricing-table__quoted">
            {{quotedRepair|formatMoney}}
        </td>
        <td class="pricing-table__assessed">
            {{assessedRepair|formatMoney}}
        </td>
    </tr>
    <tr>
        <td class="pricing-table__key">
            Paint Labour
        </td>
        <td class="pricing-table__quoted">
            {{quotedPaint|formatMoney}}
        </td>
        <td class="pricing-table__assessed">
            {{assessedPaint|formatMoney}}
        </td>
    </tr>
    <tr>
        <td class="pricing-table__key">
            <span class="font-black">TOTAL LABOUR</span>
        </td>
        <td class="pricing-table__quoted">
            <span class="font-black">{{quotedTotalLabour|formatMoney}}</span>
        </td>
        <td class="pricing-table__assessed">
            <span class="font-black">{{assessedTotalLabour|formatMoney}}</span>
        </td>
    </tr>
    <tr>
        <td class="pricing-table__key">
            Parts
        </td>
        <td class="pricing-table__quoted">
            {{quotedParts|formatMoney}}
        </td>
        <td class="pricing-table__assessed">
            {{assessedParts|formatMoney}}
        </td>
    </tr>
    <tr>
        <td class="pricing-table__key">
            Sublet
        </td>
        <td class="pricing-table__quoted">
            {{quotedSublet|formatMoney}}
        </td>
        <td class="pricing-table__assessed">
            {{assessedSublet|formatMoney}}
        </td>
    </tr>
    <tr>
        <td class="pricing-table__key">
            Materials
        </td>
        <td class="pricing-table__quoted">
            {{quotedMaterials|formatMoney}}
        </td>
        <td class="pricing-table__assessed">
            {{assessedMaterials|formatMoney}}
        </td>
    </tr>
    <tr>
        <td class="pricing-table__key">
            Others
        </td>
        <td class="pricing-table__quoted">
            {{quotedOther|formatMoney}}
        </td>
        <td class="pricing-table__assessed">
            {{assessedOther|formatMoney}}
        </td>
    </tr>
    </tbody>
</template>
<script>
    /* eslint-disable */
    import NumberFormatter from '@/components/number-formatter';
    import _ from "lodash";
    import Axios from "axios";

    export default {
        name: 'assessment-tab-quoted-assessed',
        props: {
            numberEstimate: {
                type: Number,
                default() {
                    return 0;
                }
            },
            itemslist: {
                type: Object,
                default() {
                    return {
                        Materials: [],
                        Other: [],
                        PDR: [],
                        Paint: [],
                        Part: [],
                        RR: [],
                        Repair: [],
                        Sublet: [],
                    };
                }
            },
          isDollarsMethodology: {
            type: Boolean,
            default: false,
          },
            info: {
                type: Object,
                default() {
                    return {
                        id: 0,
                        type: 'estimate',
                        number: '',
                        quotedSubtotal: 0,
                        assessedSubtotal: 0,
                    };
                },
            },
        },
        data: function () {
            return {};
        },
        methods: {},
        computed: {
            quotedRR() {
                let subtotal = 0;
              let self = this;
                _.forEach(this.itemslist, function (items, key) {
                    if (key == 'RR') {
                        _.forEach(items, function (item) {
                            if (self.isDollarsMethodology) {
                              subtotal += parseFloat(parseFloat(item.total_manual || 0).toFixed(2));
                            } else {
                              subtotal += parseFloat((parseFloat(item.hours || 0) * parseFloat(item.price || 0)).toFixed(2));
                            }
                        });
                    }
                });
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            assessedRR() {
                let subtotal = 0;
              let self = this;
                if (this.itemslist && this.itemslist.RR && this.itemslist.RR.length > 0) {
                    _.forEach(this.itemslist.RR, function (item) {
                        if (item.approveStatus && (item.approveStatus === 'approved' || item.approveStatus === 'reported')) {
                            if (self.isDollarsMethodology) {
                              if (item.adj_total_manual !== '' && item.adj_total_manual != null) {
                                subtotal += parseFloat(parseFloat(item.adj_total_manual || 0).toFixed(2))
                              } else {
                                subtotal += parseFloat(parseFloat(item.total_manual || 0).toFixed(2))
                              }
                            } else {
                              if (item.adj_hours !== '' && item.adj_hours != null) {
                                subtotal += parseFloat((parseFloat(item.adj_hours) * parseFloat(item.adj_price || item.price || 0)).toFixed(2));
                              } else {
                                subtotal += parseFloat((parseFloat(item.hours || 0) * parseFloat(item.adj_price || item.price || 0)).toFixed(2));
                              }
                            }
                        }
                    });
                }
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            quotedRepair() {
                let subtotal = 0;
              let self = this;
                _.forEach(this.itemslist, function (items, key) {
                    if (key == 'Repair') {
                        _.forEach(items, function (item) {
                            if (self.isDollarsMethodology) {
                              subtotal += parseFloat(parseFloat(item.total_manual || 0).toFixed(2))
                            } else {
                              subtotal += parseFloat((parseFloat(item.hours || 0) * parseFloat(item.price || 0)).toFixed(2));
                            }
                        });
                    }
                });
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            assessedRepair() {
                let subtotal = 0;
              let self = this;
                if (this.itemslist && this.itemslist.Repair && this.itemslist.Repair.length > 0) {
                    _.forEach(this.itemslist.Repair, function (item) {
                        if (item.approveStatus && (item.approveStatus === 'approved' || item.approveStatus === 'reported')) {
                            if (self.isDollarsMethodology) {
                              if (item.adj_total_manual !== '' && item.adj_total_manual != null) {
                                subtotal += parseFloat(parseFloat(item.adj_total_manual || 0).toFixed(2))
                              } else {
                                subtotal += parseFloat(parseFloat(item.total_manual || 0).toFixed(2))
                              }
                            } else {
                              if (item.adj_hours !== '' && item.adj_hours != null) {
                                subtotal += parseFloat((parseFloat(item.adj_hours || 0) * parseFloat(item.adj_price || item.price || 0)).toFixed(2));
                              } else {
                                subtotal += parseFloat((parseFloat(item.hours || 0) * parseFloat(item.adj_price || item.price || 0)).toFixed(2));
                              }
                            }
                        }
                    });
                }
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            quotedPaint() {
                let subtotal = 0;
              let self = this;
                _.forEach(this.itemslist, function (items, key) {
                    if (key == 'Paint') {
                        _.forEach(items, function (item) {
                            if (self.isDollarsMethodology) {
                              subtotal += parseFloat(parseFloat(item.total_manual || 0).toFixed(2))
                            } else {
                              subtotal += parseFloat((parseFloat(item.hours || 0) * parseFloat(item.price || 0)).toFixed(2));
                            }
                        });
                    }
                });
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            assessedPaint() {
                let subtotal = 0;
                let self = this;
                if (this.itemslist && this.itemslist.Paint && this.itemslist.Paint.length > 0) {
                    _.forEach(this.itemslist.Paint, function (item) {
                        if (item.approveStatus && (item.approveStatus === 'approved' || item.approveStatus === 'reported')) {
                            if (self.isDollarsMethodology) {
                              if (item.adj_total_manual !== '' && item.adj_total_manual != null) {
                                subtotal += parseFloat(parseFloat(item.adj_total_manual || 0).toFixed(2));
                              } else {
                                subtotal += parseFloat(parseFloat(item.total_manual || 0).toFixed(2));
                              }
                            } else {
                              if (item.adj_hours !== '' && item.adj_hours != null) {
                                subtotal += parseFloat((parseFloat(item.adj_hours) * parseFloat(item.adj_price || item.price || 0)).toFixed(2));
                              } else {
                                subtotal += parseFloat((parseFloat(item.hours || 0) * parseFloat(item.adj_price || item.price || 0)).toFixed(2));
                              }
                            }
                        }
                    });
                }
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            quotedTotalLabour() {
                let subtotal = 0;
                subtotal = this.quotedRR + this.quotedRepair + this.quotedPaint;
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            assessedTotalLabour() {
                let subtotal = 0;
                subtotal = this.assessedRR + this.assessedRepair + this.assessedPaint;
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            quotedParts() {
                let subtotal = 0;
                _.forEach(this.itemslist, function (items, key) {
                    if (key == 'Part') {
                        _.forEach(items, function (item) {
                            subtotal += parseFloat((parseFloat(item.qty || 1) * parseFloat(item.price || 0) * (1 + Number(item.MUP || 0) / 100)).toFixed(2));
                        });
                    }
                });
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            assessedParts() {
                let subtotal = 0;
                if (this.itemslist && this.itemslist.Part && this.itemslist.Part.length > 0) {
                    _.forEach(this.itemslist.Part, function (item) {
                        if (item.approveStatus && (item.approveStatus === 'approved' || item.approveStatus === 'reported')) {
                            if (item.adj_price !== '' && item.adj_price != null) {
                                subtotal += parseFloat(parseFloat(item.adj_price || 0).toFixed(2));
                            } else {
                                subtotal += parseFloat((parseFloat(item.qty || 1) * parseFloat(item.price || 0) * (1 + Number(item.adjMUP || 0) / 100)).toFixed(2));
                            }
                        }
                    });
                }
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            quotedSublet() {
                let subtotal = 0;
                _.forEach(this.itemslist, function (items, key) {
                    if (key == 'Sublet') {
                        _.forEach(items, function (item) {
                            subtotal += parseFloat(parseFloat((item.price || 0) * (1 + Number(item.MUP || 0) / 100)).toFixed(2));
                        });
                    }
                });
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            assessedSublet() {
                let subtotal = 0;
                if (this.itemslist && this.itemslist.Sublet && this.itemslist.Sublet.length > 0) {
                    _.forEach(this.itemslist.Sublet, function (item) {
                        if (item.approveStatus && (item.approveStatus === 'approved' || item.approveStatus === 'reported')) {
                            if (item.adj_price !== '' && item.adj_price != null) {
                                subtotal += parseFloat(parseFloat(item.adj_price || 0).toFixed(2));
                            } else {
                                subtotal += parseFloat(parseFloat((item.price || 0) * (1 + Number(item.adjMUP || 0) / 100)).toFixed(2));
                            }
                        }
                    });
                }
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            quotedMaterials() {
                let subtotal = 0;
                _.forEach(this.itemslist, function (items, key) {
                    if (key == 'Materials') {
                        _.forEach(items, function (item) {
                            subtotal += parseFloat(parseFloat(item.price || 0).toFixed(2));
                        });
                    }
                });
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            assessedMaterials() {
                let subtotal = 0;
                if (this.itemslist && this.itemslist.Materials && this.itemslist.Materials.length > 0) {
                    _.forEach(this.itemslist.Materials, function (item) {
                        if (item.approveStatus && (item.approveStatus === 'approved' || item.approveStatus === 'reported')) {
                            if (item.adj_price !== '' && item.adj_price != null) {
                                subtotal += parseFloat(parseFloat(item.adj_price || 0).toFixed(2));
                            } else {
                                subtotal += parseFloat(parseFloat(item.price || 0).toFixed(2));
                            }
                        }
                    });
                }
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            quotedOther() {
                let subtotal = 0;
                _.forEach(this.itemslist, function (items, key) {
                    if (key == 'Other') {
                        _.forEach(items, function (item) {
                            subtotal += parseFloat(parseFloat(item.price || 0).toFixed(2));
                        });
                    }
                });
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            assessedOther() {
                let subtotal = 0;
                if (this.itemslist && this.itemslist.Other && this.itemslist.Other.length > 0) {
                    _.forEach(this.itemslist.Other, function (item) {
                        if (item.approveStatus && (item.approveStatus === 'approved' || item.approveStatus === 'reported')) {
                            if (item.adj_price !== '' && item.adj_price != null) {
                                subtotal += parseFloat(parseFloat(item.adj_price || 0).toFixed(2));
                            } else {
                                subtotal += parseFloat(parseFloat(item.price || 0).toFixed(2));
                            }
                        }
                    });
                }
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            quotedSubtotal: function () {
                let subtotal = 0;
                subtotal = this.quotedRR + this.quotedRepair + this.quotedPaint + this.quotedParts + this.quotedSublet + this.quotedMaterials + this.quotedOther;
                let subtotal2 = parseFloat(parseFloat(subtotal).toFixed(2));
                this.info.quotedSubtotal = subtotal2;
                console.log('quotedSubtotal', subtotal2);
                return subtotal2;
            },
            assessedSubtotal: function () {
                let subtotal = 0;
                subtotal = this.assessedRR + this.assessedRepair + this.assessedPaint + this.assessedParts + this.assessedSublet + this.assessedMaterials + this.assessedOther;
                let subtotal2 = parseFloat(parseFloat(subtotal).toFixed(2));
                this.info.assessedSubtotal = subtotal2;
                console.log('assessedSubtotal', subtotal2);
                return subtotal2;
            },
            quotedGST() {
                let subtotal = this.quotedSubtotal * 0.1;
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            assessedGST() {
                let subtotal = this.assessedSubtotal * 0.1;
                return parseFloat(parseFloat(subtotal).toFixed(2));
            },
            isSomeItemsApproved(){
                let result = _.some(this.itemslist, function (items) {

                        let r = _.some(items, function (item) {
                            return (item && item.approveStatus != 'none')
                        });
                        return r;
                });
                return result;
            }

        },
        watch: {
            quotedSubtotal(newVal) {
                console.log('quotedSubtotal', newVal);
                this.info.quotedSubtotal = newVal;

            },
            assessedSubtotal(newVal) {
                console.log('assessedSubtotal', newVal);
                this.info.assessedSubtotal = newVal;
            }
        },
        components: {
            NumberFormatter,
        },

    };
</script>
