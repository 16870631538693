import { render, staticRenderFns } from "./items-list.vue?vue&type=template&id=0bb0afd4&scoped=true"
import script from "./items-list.vue?vue&type=script&lang=js"
export * from "./items-list.vue?vue&type=script&lang=js"
import style0 from "./items-list.vue?vue&type=style&index=0&id=0bb0afd4&prod&scoped=true&lang=css"
import style1 from "./items-list.vue?vue&type=style&index=1&id=0bb0afd4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bb0afd4",
  null
  
)

export default component.exports